<template>
  <div>
    <MainCard v-if="!isLoadingTable">
      <template v-slot:body>
        <DataTablePagination
          :page="page"
          :total-items="totalItems"
          :headers="headers"
          :items="classes"
          :search="search"
          :is-loading="isLoadingData"
          title="Kelas"
          subtitle="Halaman untuk menambahkan kelas"
          add-btn="Tambah Data"
          :total-pages.sync="totalPages"
          @change-page="paginationHandler"
          @add-item="showFormAdd"
          @edit-item="showFormEdit"
          @delete-item="confirmDestroy"
          @live-search="searchHandler"
        >
        </DataTablePagination>
      </template>
    </MainCard>
    <SkeletonLoaderTable v-else></SkeletonLoaderTable>
    <ModalDialog
      :visible="modalDialog"
      :width="width"
      :is-loading-button="isLoadingButton"
      :is-disabled-button="!formValid || !grade.class_advisor"
      :dialog="dialog"
      :header="dialog === 'add' ? 'Tambah Kelas': 'Edit Kelas'"
      :text-button="dialog === 'add' ? 'Tambah': 'Update'"
      @handler-button="handlerButton"
      @close-modal="modalDialog = false"
      @close-button="modalDialog = false"
    >
      <template v-slot:body>
        <v-row>
          <v-col
            cols="12"
            class="py-0"
          >
            <div>
              <v-text-field
                v-model="grade.name"
                label="Nama Kelas"
                dense
                outlined
              ></v-text-field>
            </div>
          </v-col>
          <v-col
            cols="12"
            class="py-0"
          >
            <div>
              <v-autocomplete
                v-model="grade.class_level"
                label="Tingkat"
                dense
                :items="class_level"
                outlined
                clearable
              ></v-autocomplete>
            </div>
          </v-col>
          <v-col
            cols="12"
            class="py-0"
          >
            <div>
              <v-autocomplete
                v-model="grade.class_advisor"
                label="Wali Kelas"
                dense
                :items="class_advisors"
                clearable
                item-text="employee.name"
                item-value="uuid"
                outlined
              ></v-autocomplete>
            </div>
          </v-col>
          <v-col
            cols="12"
            class="py-0"
          >
            <div>
              <v-autocomplete
                v-model="grade.major"
                label="Jurusan"
                item-text="name"
                item-value="name"
                :items="majors"
                dense
                outlined
                clearable
              ></v-autocomplete>
            </div>
          </v-col>
          <v-col
            cols="12"
            class="py-0"
          >
            <div>
              <v-text-field
                v-model="grade.capacity"
                label="Kapasitas"
                dense
                type="number"
                outlined
              ></v-text-field>
            </div>
          </v-col>
          <v-col
            cols="12"
            class="py-0"
          >
            <div>
              <v-textarea
                v-model="grade.description"
                label="Keterangan"
                dense
                outlined
              ></v-textarea>
            </div>
          </v-col>
        </v-row>
      </template>
    </ModalDialog>
    <ConfirmDialog
      :visible="confirmDialog"
      :is-loading-button="isLoadingButton"
      header="Hapus Kelas"
      @close-button="confirmDialog = false"
      @destroy-button="destroy"
    >
    </ConfirmDialog>
  </div>
</template>

<script>
import ConfirmDialog from '@/views/components/ConfirmDialog.vue'
import DataTablePagination from '@/views/components/DataTablePagination.vue'
import MainCard from '@/views/components/MainCard.vue'
import ModalDialog from '@/views/components/ModalDialog.vue'
import SkeletonLoaderTable from '@/views/components/SkeletonLoaderTable.vue'
import { integerValidator, required } from '@core/utils/validation'

export default {
  name: 'SchoolClass',
  components: {
    DataTablePagination,
    ModalDialog,
    ConfirmDialog,
    SkeletonLoaderTable,
    MainCard,
  },
  data() {
    return {
      page: 1,
      totalItems: 0,
      totalPages: 0,
      isLoadingButton: false,
      isLoadingTable: true,
      isLoadingData: false,
      modalDialog: false,
      confirmDialog: false,
      dialog: '',
      validation: {
        required,
        integerValidator,
      },
      headers: [
        { text: 'No', value: 'index' },
        { text: 'Nama Kelas', value: 'name' },
        { text: 'ID Kelas', value: 'uuid' },
        { text: 'Tingkat', value: 'class_level' },
        { text: 'Kapasitas', value: 'capacity' },
        { text: 'Wali Kelas', value: 'class_advisor.name' },
        { text: 'Jurusan', value: 'major' },
        { text: 'Keterangan', value: 'description' },
        { text: 'Actions', value: 'actions' },
      ],
      classes: [],
      class_level: ['I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX', 'X', 'XI', 'XII'],
      majors: [],
      service: 'class',
      search: '',
      school: [],
      school_uuid: '',
      width: 600,
      class_advisors: [],
      grade: {
        name: '',
        class_level: '',
        class_advisor: '',
        major: '',
        capacity: '',
        description: '-',
      },
      filterQuery: {
        class: '',
      },
      formValid: false,
    }
  },
  watch: {
    grade: {
      handler() {
        const valid = []
        let requiredField = []
        if (this.dialog === 'add') {
          requiredField = ['name', 'class_level', 'major', 'capacity', 'description']
        } else {
          requiredField = ['name', 'class_level', 'major', 'capacity']
        }
        Object.entries(this.grade).forEach(([key, item]) => {
          if (requiredField.includes(key)) valid.push(!!(item && item.length))
        })
        this.formValid = !valid.includes(false)
      },
      deep: true,
    },
    page: {
      handler() {
        this.getClass(this.filterQuery)
      },
    },
  },
  async mounted() {
    this.getSchoolUuid()
    await this.getClass()
    await this.getTeacher()
    await this.getMajor()
  },
  methods: {
    resetForm() {
      this.grade.name = ''
      this.grade.class_level = ''
      this.grade.class_advisor = ''
      this.grade.major = ''
      this.grade.capacity = ''
      this.grade.description = ''
    },
    showFormAdd() {
      this.dialog = 'add'
      this.modalDialog = true
      this.resetForm()
    },
    async getClass(params = { school_uuid: this.school_uuid }) {
      this.isLoadingData = true
      await this.$services.ApiServices.list(this.service, {
        ...params,
        page: this.page,
        school_uuid: this.school_uuid,
      }).then(
        async ({ data }) => {
          this.classes = await data.data.map((classes, index) => ({
            ...classes,
            index: index + 1,
          }))
          this.totalItems = data.meta.total
          this.totalPages = data.meta.last_page
        },
        err => console.error(err),
      )
      this.isLoadingTable = false
      this.isLoadingData = false
    },
    async getMajor(params = { school_uuid: this.school_uuid }) {
      await this.$services.ApiServices.list('major', { ...params, per_page: 'all' }).then(
        ({ data }) => {
          this.majors = data.data
        },
        err => console.error(err),
      )
    },
    async getTeacher() {
      await this.$services.ApiServices.list('teacher', { school: this.school_uuid, per_page: 'all' }).then(
        ({ data }) => {
          this.class_advisors = data.data
        },
        err => console.error(err),
      )
    },
    async getTeacherUuid(uuid) {
      await this.$services.ApiServices.list('teacher', uuid).then(
        ({ data }) => {
          this.class_advisors = data.data
        },
        err => console.error(err),
      )
    },
    getSchoolUuid() {
      this.school = JSON.parse(localStorage.getItem('user')).user.details
      this.school.forEach(el => {
        if (el.school === undefined) {
          this.school_uuid = el.uuid

          return
        }
        if (el.school === null) {
          this.showSnackbar({
            text: 'you dont have access to school',
            color: 'error',
          })

          return
        }
        this.school_uuid = el.school.uuid
      })

      return true
    },
    async add() {
      this.dialog = 'add'
      this.isLoadingButton = true
      await this.$services.ApiServices.add(this.service, this.grade).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.modalDialog = false
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )

      await this.getClass(this.filterQuery)
    },

    async showFormEdit(uuid) {
      this.dialog = 'edit'
      this.modalDialog = true
      await this.$services.ApiServices.get(this.service, uuid).then(
        ({ data }) => {
          this.grade = data.data
          this.grade.class_advisor = data.data.class_advisor.uuid
        },
        err => console.error(err),
      )
    },
    async update() {
      this.isLoadingButton = true
      this.formData = new FormData()
      await this.$services.ApiServices.update(
        this.service,
        {
          name: this.grade.name,
          class_level: this.grade.class_level,
          class_advisor: this.grade.class_advisor,
          major: this.grade.major,
          capacity: this.grade.capacity,
          description: this.grade.description,
        },
        this.grade.uuid,
      ).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.modalDialog = false
          this.resetForm()
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      await this.getClass(this.filterQuery)
    },
    handlerButton() {
      if (this.dialog === 'add') this.add()
      else this.update()
    },
    confirmDestroy(uuid) {
      this.grade.uuid = uuid
      this.confirmDialog = true
    },
    async destroy() {
      this.isLoadingButton = true
      await this.$services.ApiServices.destroy(this.service, this.grade.uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.confirmDialog = false
          this.resetForm()
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      await this.getClass(this.filterQuery)
    },
    searchHandler(data) {
      this.page = 1
      this.filterQuery.class = data
      this.getClass(this.filterQuery)
    },
    paginationHandler(pageNumber) {
      this.page = pageNumber
    },
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
  },
}
</script>

<style>
</style>
