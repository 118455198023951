<template>
  <div>
    <v-container class="pa-6 mx-0">
      <v-row>
        <v-col
          md="4"
          xl="4"
          cols="12"
          sm="12"
        >
          <h2>Pengaturan Sekolah</h2>
          <small style="white-space: normal;">Halaman untuk pengaturan sekolah</small>
        </v-col>
      </v-row>
    </v-container>
    <v-divider></v-divider>

    <v-container class="pa-6 mx-0">
      <v-row>
        <v-col
          xl="2"
          lg="2"
          md="2"
          sm="4"
          cols="4"
        >
          <h3>Semester</h3>
        </v-col>
        <v-col
          cols="8"
        >
          <div class="d-flex">
            <div class="d-inline">
              <span class="me-4">:</span>
            </div>
            <div>
              <v-radio-group
                v-model="semester"
                class="mt-0"
                row
              >
                <v-radio
                  v-for="(semester,index) in semesters"
                  :key="index"
                  class="d-flex"
                  :label="semester.title"
                  :value="semester.value"
                  @change="selectedSemester(semester.value)"
                ></v-radio>
              </v-radio-group>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          xl="2"
          lg="2"
          md="2"
          sm="4"
          cols="4"
        >
          <h3>Tahun Ajaran</h3>
        </v-col>
        <v-col
          xl="4"
          lg="4"
          md="4"
          sm="8"
          cols="8"
        >
          <div class="d-flex">
            <span class="me-4">:</span>
            <v-autocomplete
              v-model="school_year"
              label="Tahun Ajaran"
              outlined
              dense
              width="300"
              return-object
              :items="study_years"
              item-text="title"
              item-value="value"
              @change="selectedYear(school_year.value)"
            ></v-autocomplete>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          xl="2"
          lg="2"
          md="2"
          sm="4"
          cols="4"
        >
          <h3>Jurusan</h3>
        </v-col>
        <v-col cols="8">
          <span class="me-4">:</span>
          <span>Pilih Jurusan dari tabel dibawah.</span>
        </v-col>
      </v-row>
    </v-container>
    <v-data-table
      :page="page"
      :headers="headers"
      :items="majors"
      :server-items-length="totalItems"
      :total-pages.sync="totalPages"
      hide-default-footer
      class="elevation-0"
    >
      <template v-slot:item.checkbox="{item}">
        <v-checkbox
          v-model="selectedMajors"
          :value="item.uuid"
          @change="handleMajor(item.uuid, selectedMajors)"
        ></v-checkbox>
      </template>
    </v-data-table>
    <div class="d-flex justify-space-between pt-2 pb-2">
      <div class="ml-4 mt-3">
        <h5>Total item: {{ totalItems }}</h5>
      </div>
      <v-pagination
        v-model="page"
        :length="totalPages"
        total-visible="7"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SchoolSetting',
  data() {
    return {
      study_years: [
        { index: 1, title: '2021/2022', value: '2021/2022' },
        { index: 2, title: '2022/2023', value: '2022/2023' },
        { index: 3, title: '2023/2024', value: '2023/2024' },
        { index: 4, title: '2024/2025', value: '2024/2025' },
        { index: 5, title: '2025/2026', value: '2025/2026' },
        { index: 6, title: '2026/2027', value: '2026/2027' },
        { index: 7, title: '2027/2028', value: '2027/2028' },
        { index: 8, title: '2028/2029', value: '2028/2029' },
        { index: 9, title: '2029/2030', value: '2029/2030' },
      ],
      semesters: [
        { index: 1, title: 'Ganjil', value: 'Ganjil' },
        { index: 2, title: 'Genap', value: 'Genap' },
      ],
      majors: [],
      isLoadingButton: false,
      page: 1,
      serviceMajor: 'majorschool',
      totalItems: 0,
      totalPages: 0,
      semester: '',
      majorSchools: [],
      headers: [
        { text: 'No', value: 'index' },
        { text: 'Jurusan', value: 'name' },
        { text: 'Pilih', value: 'checkbox' },
      ],
      majorSchool: [],
      selectedMajors: [],
      selectedMajor: [],
      major_uuid: '',
      school_uuid: '',
      school: [],
      school_year: '',
    }
  },
  watch: {
    page: {
      handler() {
        this.getMajor()
        this.getDataSchool()
      },
    },
  },
  async mounted() {
    await this.getSchoolUuid()
    this.getDataSchool()
    this.getMajor()
  },
  methods: {
    getSchoolUuid() {
      this.school = JSON.parse(localStorage.getItem('user')).user.details
      this.school.forEach(el => {
        if (el.uuid === undefined) {
          this.school_uuid = el.uuid

          return
        }
        if (el.uuid === null) {
          this.showSnackbar({
            text: "You don't have access to school",
            color: 'error',
          })

          return
        }
        this.school_uuid = el.uuid
      })

      return true
    },
    async getMajor(params = {}) {
      this.isLoadingData = true
      await this.$services.ApiServices.list('major', { ...params, page: this.page }).then(({ data }) => {
        this.majors = data.data.map((major, index) => ({
          ...major,
          index: index + 1,
        }))
        this.totalItems = data.meta.total
        this.totalPages = data.meta.last_page
      })
      this.isLoadingData = false
    },
    async getDataSchool() {
      await this.$services.ApiServices.get('school', this.school_uuid).then(({ data }) => {
        this.majorSchool = data.data.major
        this.majorSchool.forEach(selectedMajor => {
          this.selectedMajors.push(selectedMajor.uuid)
        })
        this.semester = data.data.semester
        this.school_year = data.data.tahun_ajaran
      })
    },
    async selectedSemester(semester) {
      this.formData = new FormData()
      this.formData.append('semester', semester)
      await this.$services.ApiServices.update('school', this.formData, this.school_uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
        },
        err => console.error(err),
      )
    },
    async selectedYear(year) {
      this.formData = new FormData()
      this.formData.append('tahun_ajaran', year)
      await this.$services.ApiServices.update('school', this.formData, this.school_uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
        },
        err => console.error(err),
      )
    },
    async handleMajor(uuid, selectedMajors) {
      const major = {
        major_uuid: uuid,
        school_uuid: this.school_uuid,
      }
      if (!selectedMajors.includes(uuid)) {
        await this.$services.MajorServices.destroy('majorschool', uuid, this.school_uuid).then(
          () => {
            this.showSnackbar({
              text: 'Delete major school has successfully',
              color: 'error',
            })
          },
          err => {
            console.error(err)
          },
        )
      } else {
        await this.$services.MajorServices.add('majorschool', major).then(
          () => {
            this.showSnackbar({
              text: 'Add major school has successfully',
              color: 'success',
            })
          },
          err => {
            console.error(err)
          },
        )
      }
    },
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
  },
}
</script>

<style>
.shadow-none {
  box-shadow: none !important;
}
</style>
